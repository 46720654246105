/**
 * @memberOf momentshare.models.subscription
 * @constructor
 */
export class SubscriptionOption {
  constructor({
    id,
    cost,
    description,
    vat,
    vatPercentage,
  }) {
    this.id = id
    this.cost = cost
    this.description = description
    this.vat = vat
    this.vatPercentage = vatPercentage
  }
}

import { mapSubscriptionOptions, mapSubscriptionOption } from "~/lib/mappers/subscription-mapper"
import { mapDiscountCode } from "~/lib/mappers/discount-code-mapper"

/**
 * @typedef {PaymentService}
 * @alias this.$paymentService
 */
export class PaymentService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.promiseCache = {}
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @param {string|null} [country]
   * @returns {Promise<momentshare.models.subscription.SubscriptionOption[]>} Options for subscription
   */
  getSubscriptionOptions(country = null) {
    let key = `subscription-options`

    if (country) {
      key += `-${country}`
    }

    const cachedResponse = this.getPromiseFromCache(key)

    if (cachedResponse) {
      return cachedResponse
    }

    const promise = this.$apiService.instance.get(`/payment/subscription-options${country ? `?country=${country}` : '' }`)
      .then(response => mapSubscriptionOptions(response.data))

    return this.setPromiseToCache(key, promise)
  }

  /**
   * @param {number} id
   * @param {string|null} [country]
   * @returns {Promise<momentshare.models.subscription.SubscriptionOption>} Option for subscription
   */
  getSubscriptionOption(id, country = null) {
    let key = `subscription-option-${id}`

    if (country) {
      key += `-${country}`
    }

    const cachedResponse = this.getPromiseFromCache(key)

    if (cachedResponse) {
      return cachedResponse
    }

    const promise = this.$apiService.instance.get(`/payment/subscription-option/${id}${country ? `?country=${country}` : '' }`)
      .then(response => mapSubscriptionOption(response.data))

    return this.setPromiseToCache(key, promise)
  }

  /**
   * @param {string} discountCode
   * @returns {Promise<momentshare.models.payment.DiscountCode>}
   */
  checkDiscountCode(discountCode) {
    return this.$apiService.instance.post(`/payment/check-discount-code`, { discountCode })
      .then(response => mapDiscountCode(response.data))
  }

  setPromiseToCache(key, promise) {
    this.promiseCache[key] = promise

    return promise
  }

  getPromiseFromCache(key) {
    return this.promiseCache[key]
  }
}

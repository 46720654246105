import { CONSENT_COOKIE_NAME } from '~/lib/constants/cookies'

export class AnalyticsService {

  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.$clientCookieService = null
    const { gtag } = useGtag()
    this.gtag = gtag
  }

  init() {
    this.$clientCookieService = this.nuxtApp.$clientCookieService
  }

  /**
   * @param {momentshare.models.order.Order} order
   * @returns {void}
   */
  purchaseEvent(order) {
    const totalValue = order.orderLines.reduce((acc, line) => acc + line.cost, 0)
    const totalVat = order.orderLines.reduce((acc, line) => acc + line.vat, 0)
    const coupon = order.description.indexOf('DISCOUNT CODE') > -1 ? this.getDiscountCodeFromDescription(order.description) : null
    const items = this.mapOrderLinesToEventItems(order.orderLines)
    const gclid = this.getGclid()

    this.gtag('event', 'purchase', {
      ecommerce: {
        transaction_id: order.id,
        value: parseFloat(totalValue.toFixed(2)),
        vat: parseFloat(totalVat.toFixed(2)),
        currency: "EUR",
        ...(gclid && { gclid }),
        ...(coupon && { coupon }),
        items,
      },
    })

    // Gclid needs to be reported only once
    this.removeGclid()
  }

  getConsentCookieData() {
    try {
      return JSON.parse(this.$clientCookieService.get(CONSENT_COOKIE_NAME))
    } catch (error) {
      return {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'granted',
      }
    }
  }

  updateConsentCookie(cookieData) {
    this.$clientCookieService.set(CONSENT_COOKIE_NAME, JSON.stringify(cookieData), { expires: 180 })

    this.gtag('consent', 'update', cookieData)
  }

  getDiscountCodeFromDescription(description) {
    const from = description.indexOf('CODE') + 5
    const end = description.indexOf(')')

    return description.substring(from, end)
  }

  saveGclid(gclid) {
    this.$clientCookieService.set('ms_gclid', gclid, { expires: 7 })
  }

  getGclid() {
    return this.$clientCookieService.get('ms_gclid')
  }

  removeGclid() {
    return this.$clientCookieService.delete('ms_gclid')
  }

  mapOrderLinesToEventItems(orderLines) {
    return orderLines.map(orderLine => {
      return {
        item_id: orderLine.id,
        item_name: orderLine.description,
        price: parseFloat(orderLine.cost.toFixed(2)),
        quantity: 1,
      }
    })
  }
}

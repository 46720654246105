import axios from 'axios'
import { mapGoogleConnectedDriveData } from '~/lib/mappers/connected-drive-mapper'

/**
 * @typedef {GoogleService}
 * @alias this.$googleService
 */
export class GoogleService {
  constructor(nuxtApp) {
    this.config = nuxtApp.$config.public
    this.nuxtApp = nuxtApp
    this.clientId = this.config.googleClientId
    this.redirectUrl = this.config.googleRedirectUrl
    this.adminRedirectUrl = this.config.adminRedirectUrl
    this.googleAdsClientId = this.config.googleAdsClientId
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @returns {Promise<Array>}
   */
  async getAllGoogleFonts() {
    const fonts = await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBWb8F4wkGjJFW3QTEjq6WTXRgQWFrMsqE&sort=popularity`).then(response => response.data)
    const items = fonts.items
    return items.map(font => font.family).slice(0, 200)
  }

  /**
   * @param {Array} fontNames
   * @returns {void}
   */
  async setGoogleFonts(fontNames){
    const body = document.querySelector('body')

    fontNames.forEach(fontName => {
      const formattedFontName = fontName.replace(/ /g, '+')
      const script = document.createElement('link')
      script.rel = 'stylesheet'
      script.href = `https://fonts.googleapis.com/css2?family=${formattedFontName}:wght@300;400;700&display=swap`

      body.append(script)
    })
  }

  /**
   * @returns {void} consentUrl
   */
  getConsent() {
    const params = {
      client_id: this.clientId,
      redirect_uri: this.redirectUrl,
      access_type: 'offline',
      scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile',
      prompt: 'consent',
      response_type: 'code',
    }

    this.handleGoogleOAuth(params)
  }

  /**
   * @returns {void} consentUrl
   */
  getAdsApiConsent() {
    const params = {
      client_id: this.googleAdsClientId,
      redirect_uri: this.adminRedirectUrl,
      access_type: 'offline',
      scope: 'https://www.googleapis.com/auth/adwords',
      prompt: 'consent',
      response_type: 'code',
    }

    this.handleGoogleOAuth(params)
  }

  /**
   * @returns {void} consentUrl
   */
  handleGoogleOAuth(params) {
    const oAuthEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form')
    form.setAttribute('method', 'GET') // Send as a GET request.
    form.setAttribute('action', oAuthEndpoint)

    // Add form parameters as hidden input values.
    for (const p in params) {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', p)
      input.setAttribute('value', params[p])
      form.appendChild(input)
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form)
    form.submit()
  }


  /**
   * @returns {Promise<momentshare.models.user.ConnectedDriveData>}
   */
  getGoogleDriveUserData() {
    return this.$apiService.instance.get('connector/google/user').then(response => mapGoogleConnectedDriveData(response.data))
  }

  /**
   * @param {string} code
   * @returns {Promise<string>}
   */
  setGoogleTokens(code) {
    return this.$apiService.instance.post('connector/google/set-google-tokens', { code })
  }

  /**
   * @returns {Promise<string>}
   */
  deleteGoogleTokens() {
    return this.$apiService.instance.delete('connector/google/delete-google-tokens')
  }
}

/**
 * @memberOf momentshare.models.order
 * @constructor
 */
export class Order {
  constructor({
    id,
    totalInclVat,
    paymentStatus,
    description,
    createdAt,
    orderLines,
    address,
    pdfLink,
  }) {
    this.id = id
    this.totalInclVat = totalInclVat
    this.paymentStatus = paymentStatus
    this.description = description
    this.createdAt = createdAt
    this.orderLines = orderLines
    this.address = address
    this.pdfLink = pdfLink
  }
}

import { Order } from '~/lib/models/order'
import { mapSubscriptionOptions } from '~/lib/mappers/subscription-mapper'
import { mapUserAddress } from '~/lib/mappers/user-mapper'

export function mapOrders(orders) {
  return orders.map(order => mapOrder(order))
}

export function mapOrder(order) {
  return new Order({
    id: order.id,
    totalInclVat: order.totalInclVat,
    paymentStatus: order.paymentStatus,
    description: order.description,
    createdAt: new Date(order.createdAt),
    orderLines: mapSubscriptionOptions(order.orderLines),
    address: mapUserAddress(order.address),
    pdfLink: order.pdfLink,
  })
}

import { mapEventsTotalSize, mapEventsWithMetaData } from '~/lib/mappers/admin-mapper'

/**
 * @typedef {AdminService}
 * @alias this.$adminService
 */
export class AdminService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.config = nuxtApp.$config.public
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @returns {Promise<{
   *   events: momentshare.models.admin.EventWithMetaData[],
   *   total: momentshare.models.admin.EventsTotalSize,
   * }>}
   */
  getEventsData(pageLimit, page, orderBy, orderDirection, searchText, eventDate) {
    const params = new URLSearchParams({
      ...pageLimit && { pageLimit },
      ...page && { page },
      ...orderBy && { orderBy },
      ...orderDirection && { orderDirection },
      ...searchText && { searchText },
      ...eventDate && { eventDate: new Date(eventDate).toISOString() },
    })
    const formattedSearchParams = params.toString()
    const formattedQueryParams = formattedSearchParams.length ? `?${formattedSearchParams}` : ''

    return this.$apiService.instance.get(`/admin/events/meta-data${formattedQueryParams}`)
      .then(response => ({
        events: mapEventsWithMetaData(response.data.events, this.config.filesPath),
        total: mapEventsTotalSize(response.data.total),
      }))
  }

  /**
   * @param {momentshare.models.payment.DiscountCode} discountCode
   * @returns {Promise<string>}
   */
  createDiscountCode(discountCode) {
    return this.$apiService.instance.post('/admin/discount-code', discountCode)
  }

  /**
   * @param {string} eventId
   * @returns {Promise<string>}
   */
  sendReviewMail(eventId) {
    return this.$apiService.instance.post('/admin/events/send-review-mail', { eventId })
  }

  /**
   * @param {string} eventId
   * @returns {Promise<string>}
   */
  deleteEvent(eventId) {
    return this.$apiService.instance.delete(`/admin/events/delete-event/${eventId}`)
  }

  /**
   * @param {string} code
   * @returns {Promise<string>}
   */
  updateGoogleAdsToken(code) {
    return this.$apiService.instance.post(`/admin/ads/token`, { code })
  }
}

/**
 * @memberOf momentshare.models.admin
 * @constructor
 */
export class EventsTotalSize {
  /**
   * @param {number} totalUsageGB
   * @param {number} totalUsageTB
   */
  constructor({
    totalUsageGB,
    totalUsageTB,
  }) {
    this.totalUsageGB = totalUsageGB
    this.totalUsageTB = totalUsageTB
  }
}
